import { requester as axios } from "./axios-client";
export async function getCovidData({ nodeRequest } = {}) {
  const resp = await axios({
    method: "get",
    url: "/covid",
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json"
  });
  return resp.data;
}
export async function calculateCovidRisk({ nodeRequest, coordinates } = {}) {
  const resp = await axios({
    method: "get",
    url: "/covid/calculate-risk",
    params: { coordinates },
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json"
  });
  return resp.data;
}
export async function getHotspotData({ nodeRequest } = {}) {
  const resp = await axios({
    method: "get",
    url: "/covid/hotspot",
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json"
  });
  return resp.data;
}
