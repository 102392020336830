import { requester as axios } from "./axios-client";
export async function getLanguages({ nodeRequest, countryCode } = {}) {
  const resp = await axios({
    method: "get",
    url: "/glances/language",
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json",
    params: {
      countryCode:
        nodeRequest && nodeRequest.userCountryCode
          ? nodeRequest.userCountryCode
          : undefined
    }
  });
  return resp.data;
}
export async function selectLanguages(language) {
  const resp = await axios({
    method: "post",
    url: "/glances/language",
    data: { language }
  });
  return resp.data;
}
