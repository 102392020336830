export function chunkArray(myArray, chunk_size) {
  let index = 0;
  const arrayLength = myArray.length;
  const tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    tempArray.push(myChunk);
  }

  return tempArray;
}
export function merge(...arrs) {
  const arr = [];
  for (let i = 0; i < arrs.length; i++) {
    arr.push(...arrs[i]);
  }
  return arr;
}
export function firstNElement(arr, n) {
  const first = [];
  const rest = [];
  for (let i = 0; i < n && i < arr.length; i++) {
    first.push(arr[i]);
  }
  for (let i = n; i < arr.length; i++) {
    rest.push(arr[i]);
  }
  return { first, rest };
}
