import { requester as axios } from "./axios-client";
export async function getInsurancePackages({ nodeRequest } = {}) {
  const resp = await axios({
    method: "get",
    url: "/insurance-packages",
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json"
  });
  return resp.data;
}
