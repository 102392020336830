import { chunkArray } from "./array";
import { getGlances, getDemoGlances, getBubbles } from "../services";

export async function fetchInBatch({ ids, size = 20 }) {
  const chunks = chunkArray(ids, size);
  const tasks = [];
  const result = [];
  for (const _ids of chunks) {
    const task = getGlances({ ids: _ids });
    tasks.push(task);
  }
  for (const task of tasks) {
    try {
      const glances = await task;
      result.push(...glances);
    } catch (err) {
      console.error(err);
    }
  }
  return result;
}

export async function fetchDemoInBatch({ ids, size = 20 }) {
  const chunks = chunkArray(ids, size);
  const tasks = [];
  const result = [];
  for (const _ids of chunks) {
    const task = getDemoGlances({ ids: _ids });
    tasks.push(task);
  }
  for (const task of tasks) {
    try {
      const glances = await task;
      result.push(...glances);
    } catch (err) {
      console.error(err);
    }
  }
  return result;
}

export async function fetchFirstNGlance({ ids, nodeRequest, count }) {
  const chunks = chunkArray(ids, 40);
  const tasks = [];
  for (const _ids of chunks) {
    const task = await getGlances({ nodeRequest, ids: _ids });
    tasks.push(...task);
    if (tasks.length >= count) {
      break;
    }
  }
  tasks.length = count;
  return tasks;
}

export async function fetchBubbles({ ids, nodeRequest }) {
  const bubbles = await getBubbles({ nodeRequest, ids: ids });
  return bubbles;
}

export async function fetchFirstNDemoGlance({ ids, nodeRequest, count }) {
  const chunks = chunkArray(ids, 40);
  const tasks = [];
  for (const _ids of chunks) {
    const task = await getDemoGlances({ nodeRequest, ids: _ids });
    tasks.push(...task);
    if (tasks.length >= count) {
      break;
    }
  }
  tasks.length = count;
  return tasks;
}

export function extractImageUrl(supportedImages) {
  const images = supportedImages || [];
  let imgUrl;
  const resolvedImages = {};
  for (let i = 0; i < images.length; i++) {
    imgUrl =
      images[i].url.indexOf("https") > -1
        ? images[i].url
        : images[i].url.replace("http", "https");
    resolvedImages[images[i].width] = imgUrl;
  }
  return extractImgBasedOnDevice(resolvedImages);
}

function extractImgBasedOnDevice(resolvedImages) {
  const imagesArr = { ...resolvedImages };
  if (process.browser) {
    const imgRes = window.screen.width * window.devicePixelRatio;
    switch (true) {
      case imgRes < 480:
        return pickImage("480", imagesArr);
      case imgRes < 540:
        return pickImage("540", imagesArr);
      case imgRes < 720:
        return pickImage("720", imagesArr);
      case imgRes < 1080:
        return pickImage("1080", imagesArr);
      case imgRes < 1440:
        return pickImage("1080", imagesArr);
      default:
        return pickImage("1440", imagesArr);
    }
  } else {
    return pickImage("1440", imagesArr);
  }
}

function pickImage(item, imgObj) {
  const resArr = ["480", "540", "720", "1080", "1440"];
  const index = resArr.indexOf(item);
  const right = index;
  let left = index - 1;
  for (let i = right; i < resArr.length; i++) {
    if (imgObj[resArr[i]]) {
      return imgObj[resArr[i]];
    }
  }
  while (left >= 0) {
    if (imgObj[resArr[left]]) {
      return imgObj[resArr[left]];
    }
    left--;
  }
  return imgObj[Object.keys(imgObj)[0]];
}

export function extractPNGImageUrl(supportedImages) {
  const images = supportedImages || [];
  let imgUrl;
  for (let i = 0; i < images.length; i++) {
    imgUrl =
      images[i].url.indexOf("https") > -1
        ? images[i].url
        : images[i].url.replace("http", "https");
    if (images[i].width === 1440) {
      return imgUrl.replace("/dynamic/", "/original/").replace(".webp", ".png");
    }
  }
}
export function extractThumbnail(glance) {
  const images = (glance && glance.image && glance.image.supportedImages) || [];
  const [firstImage] = images;
  if (firstImage && firstImage.url) {
    const { height, url, width } = firstImage;
    return url
      .replace(`dynamic/${width}x${height}`, "thumbnail")
      .replace(".webp", ".jpg")
      .replace("http:", "https:");
  } else {
    return "";
  }
}
//https://x.g.l.inmobicdn.net/public/thumbnail/36842e3557bd320e257d63abdfa0a6993b543f78.jpg
// height: 2560
// url: "http://x.g.l.inmobicdn.net/public/dynamic/1440x2560/a13160fecd08bd64d1cb656c581ca7ef266d0346.webp"
// width: 1440

export function extractSummary(glance) {
  if (glance && glance.type === "STORY" && glance.peek) {
    if (glance.peek.type === "ARTICLE") return glance.peek.articlePeek.summary;
    else if (glance.peek.type === "VIDEO") return glance.peek.videoPeek.summary;
  }
}

export function fetchVideo(glance) {
  if (glance && glance.peek && glance.peek.type === "NATIVE_VIDEO") {
    return true;
  } else {
    return false;
  }
}

export function extractTitle(glance) {
  if (
    glance &&
    glance.type === "STORY" &&
    glance.peekData &&
    glance.peekData.title
  ) {
    return glance.peekData.title.trim();
  }
}

export function extractSourceName(glance) {
  if (glance && glance.type === "STORY" && glance.peekData.sourceName) {
    return glance.peekData.sourceName;
  } else {
    return "Glance";
  }
}

// export function fetchFirstNCategories(categories, n) {
//   return categories.splice(0, n);
// }

export function filterCategories(categories) {
  const language = ["#hindi", "#tamil", "#telugu", "#indonesia"];
  return categories.filter(cat => language.indexOf(cat.id) < 0);
}

export function getLanguagePreference(lang) {
  const languageSupported = ["hi", "te", "ta", "mr", "kn", "bn"];
  const langPreference = ["en"];
  if (languageSupported.includes(lang)) {
    langPreference.push(lang);
  }
  return langPreference;
}
