import axios from "axios";
export { checkVersion } from "./version";
const dev = process.env.NODE_ENV !== "production";
const browser = process.browser;
let requester;
if (dev) {
  const https = require("https");
  const agent = new https.Agent({
    rejectUnauthorized: false
  });
  requester = axios.create({
    httpsAgent: agent
  });
  requester.defaults.baseURL = browser
    ? "/roposo-api/v1"
    : "https://localhost:3000/roposo-api/v1";
  requester.defaults.httpsAgent = agent;
} else {
  requester = axios.create({
    baseURL: browser ? "/roposo-api/v1" : "http://localhost:3000/roposo-api/v1"
  });
}
export { requester };
