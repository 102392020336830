import { requester as axios } from "./roposo-axios-client";

export async function getDefaultRoposoStories({
  nodeRequest,
  nextLink,
  tokenExpire
} = {}) {
  const resp = await axios({
    method: "get",
    url: "/default/stories",
    params: {
      nextLink,
      tokenExpire
    },
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json"
  });
  const { data } = resp;
  return data;
}

export async function roposoStoryEvent({ nodeRequest, uri, tokenExpire } = {}) {
  const resp = await axios({
    method: "get",
    url: "/story/event",
    params: {
      uri,
      tokenExpire
    },
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json"
  });
  const { data } = resp;
  return data;
}
