import axios from "axios";
import https from "https";
import http from "http";

export { checkVersion } from "./version";
const dev = process.env.NODE_ENV !== "production";
const browser = process.browser;
let requester;
if (dev) {
  const agent = new https.Agent({
    rejectUnauthorized: false
  });
  requester = axios.create({
    httpsAgent: agent
  });
  requester.defaults.baseURL = browser
    ? "/api/v1"
    : "https://localhost:3000/api/v1";
  requester.defaults.httpsAgent = agent;
} else {
  requester = axios.create({
    baseURL: browser ? "/api/v1" : "http://localhost:3000/api/v1",
    /**
     * https://rakshanshetty.in/nodejs-http-keep-alive/
     * https://stackoverflow.com/questions/63064393/getting-axios-error-connect-etimedout-when-making-high-volume-of-calls
     */
    timeout: 30000,
    httpsAgent: new https.Agent({ keepAlive: true }),
    httpAgent: new http.Agent({ keepAlive: true })
  });
}
export { requester };
