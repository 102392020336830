import { requester as axios } from "./axios-client";
export async function getCategories({ nodeRequest, countryCode } = {}) {
  const resp = await axios({
    method: "get",
    url: "/glances/category",
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json",
    params: {
      countryCode:
        nodeRequest && nodeRequest.userCountryCode
          ? nodeRequest.userCountryCode
          : undefined
    }
  });
  return resp.data;
}
export async function selectCategories(category) {
  const resp = await axios({
    method: "post",
    url: "/glances/category",
    data: { category }
  });
  return resp.data;
}
